@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@font-face {
    font-family: 'AldotheApache';
    src: url('./fonts/AldotheApache.eot');
    src: url('./fonts/AldotheApache.eot?#iefix') format('embedded-opentype'),
         url('./fonts/AldotheApache.ttf') format('truetype'),
         url('./fonts/AldotheApache.woff') format('woff'),
         url('./fonts/AldotheApache.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Pixellari';
    src: url('./fonts/Pixellari.eot');
    src: url('./fonts/Pixellari.eot?#iefix') format('embedded-opentype'),
         url('./fonts/Pixellari.ttf') format('truetype'),
         url('./fonts/Pixellari.woff') format('woff'),
         url('./fonts/Pixellari.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: none;
    scrollbar-color: #3c3c3c #1E1E1E;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #1E1E1E;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #3c3c3c;
    border-radius: 5px;
  }

*
{
    margin:0;
    padding:0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

.mobile-button{
  display: none;
}

.mobile-menu{
  display: none;
}

a{
  text-decoration: none;
}

video{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -5;
  object-fit:cover;
}

#bottom-bg{
  position: fixed;
  bottom: 0;
  z-index: -1;
}

.disabled-button{
  cursor:not-allowed;
}

.relative{
  position: relative;
}

body{
    overflow-x: hidden;
    min-height: 100vh;
    background-color: #00041c;
    scroll-behavior: smooth;
  }

header{
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header-menu{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.header-menu li{
  margin-left: 0.5vw;
  text-decoration: none;
  color:white;
}

.header-menu a{
  color:white;
  font-family: 'AldotheApache', sans-serif;
  font-size: 1.5vw;
}

.details-page{
  display: flex;
  flex-direction:column;
  background: url(./images/details.png);
  overflow-y: hidden;
  margin-top: 50px;
  flex-wrap: wrap;
}

.details-section{
  display:'flex'; 
  flex-direction: row;
  overflow-y: hidden;
}


#next-button{
  background: url(./images/next.png);
  width: 94px;
  height: 77px;
  border:0;
  margin-top: 25px;
  cursor: pointer;
  margin-right: 20px;

}

.details-button{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 150px;
}

#prev-button{
  background: url(./images/next.png);
  width: 94px;
  height: 77px;
  border:0;
  margin-top: 25px;
  cursor: pointer;
  transform: scaleX(-1);
  margin-left: 20px;

}

.right-details-container h3{
  font-size: 30pt;
}

.details-container{
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 50px;
  justify-content: center;
  overflow-y: hidden;
}

.right-details-container{
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 50px;
  justify-content: center;
}

.details-container a{
  padding-top: 5px;
  color: #cccccc;
  font-weight: 900;
}

.details-container img{
  width: 300px;
}

@keyframes starsanimation{
  0%   { background-position: 0px 0px; }
  100% { background-position: 0px -41040px }
}

@keyframes walletbutton {
  0%   { background-position: 0px 0px; }
  100% { background-position: 0px -500px }
  }
  
  @keyframes btnpress {
    0%   { background-position: 0px 0px; }
    100% { background-position: 0px -1577px }
  }

  #mint-button{
    background: url(./images/button.png) 0 0;
    width: 220px;
    height: 83px;
    animation-name: btnpress;
    animation-duration: 1s;
    animation-timing-function: steps(19, end);
    animation-fill-mode: forwards;
    animation-play-state: paused;
    border: 0;
  }


#connect-wallet{
    background: url(./images/wallet.png) 0 0;
    cursor: pointer;
    width: 220px;
    height: 50px;
    animation-name: walletbutton;
    animation-duration: 1s;
    animation-timing-function: steps(10, end);
    animation-fill-mode:forwards;
    animation-iteration-count: infinite;
    border: 0;
    padding: 0;
}

#my-wallet{
  background: url(./images/mywallet.png) 0 0;
  cursor: pointer;
  width: 220px;
  height: 50px;
  animation-name: walletbutton;
  animation-duration: 1s;
  animation-timing-function: steps(10, end);
  animation-fill-mode:forwards;
  animation-iteration-count: infinite;
  border: 0;
  padding: 0;
  display:block;

}

.logo{
    color: black;
    font-size: 8vh;
    margin-left: 10vh;
    font-family: 'AldotheApache', sans-serif;
}

.container{
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 50vh;
  justify-content: center;
}

.mint-container{
  text-align: center;
  justify-content: center;
  width: 50%;
  background-color: #1e1e1e7e;
  border-radius: 10px;
}

.mint-container input{
  width: 20%;
}

.mint-container h1{
  color: white;
  font-family: 'AldotheApache', sans-serif;
  text-shadow: 1px 5px #ee4e27;
  letter-spacing: 5px;
  font-size: 4vw;
}

.select-container {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
}


input[type="radio"]:disabled {
  border: 2px solid #5a5a5a;
  background-color: #5a5a5a;
}

input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 4px;
  background-clip: content-box;
  border: 2px solid #bbbbbb;
  background-color: #e7e6e7;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 15px;
}

input[type="radio"]:checked {
  background-color: #ff4800;
}

.select-container form label{
  font-size: 1.5rem;
  font-family: 'Pixellari', sans-serif;
}

.custom-select {
  appearance: none;
  width: 200px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  background-color: transparent;
  color: white;
}

option{
background-color: #ee4e27;
padding: 10px;
border-radius: 4px;

}

.custom-select:focus {
  outline: none;
  box-shadow: none;
}

h2{
  color: white;
  font-family: 'AldotheApache', sans-serif;
  text-shadow: 1px 5px #ff4800;
  letter-spacing: 1px;
  font-size: 4rem;
  padding-bottom: 0.5vw;
}

.Mint{
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
}

.info-container{
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-showcase{
  display: flex;
  flex-direction: column;
  color: white;
  width: 90%;
  padding: 1rem;
}

.info-showcase img{
  width: 15vw;
}

.info-showcase h3{
  text-align: center;
  font-size: 1.5rem;
}


.nfts-container{
  display: flex;
  flex-direction: row;
  height: 20vw;
  align-items: center;
  justify-content:flex-start;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(30,30,30,1) 100%);
}

.number-input{
  width: 200px;
  padding: 10px;
  border-radius: 100px;
  font-size: 1rem;
  margin-bottom: 20px;
  text-align: center;
}

textarea:focus, input:focus{
  outline: none;
}

.nfts-container img {
  height: 15vw;
  padding-left: 2vw;
  padding-right: 2vw;
  animation: slide-images 20s linear infinite;
}

.collections-page
{
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200%;
  position: relative;
}
.collections-page a
{
  color: #ff4800;
  font-weight: 800;
}

.collections-page h3
{
  color: white;
  font-family: 'AldotheApache', sans-serif;
  text-shadow: 1px 3px #ff4800;
  letter-spacing: 2px;
  font-size: 40pt;
}

.collections-container{
  color: white;
  position: relative;
  width: 100%;
}

.collections-container img{
  height: 256px;
  width: 256px;
  margin-left: 10px;
}

.collection-traits{
  margin-top: 100px;  
  position: relative;
  width: 20%;
}

#traits-msg{
  position: absolute;
  bottom: 8px;
  font-size: 10pt;
  color: #00041c;
}

.traits-modal{
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #00041cbe;
  justify-content: center;
  align-items: center;
}

.modal-container{
  width: 50%;
  height: 50%;
  background-color: #00041c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
}

.modal-container h2{
  font-family: 'Pixellari', sans-serif;
}

.modal-container a{
  margin-top: 20px;
  cursor: pointer;
}

.collection-items{
  display: flex;
  flex-wrap: wrap;
  font-size: 20pt;
  text-align: center;
  justify-content: center;
}


@keyframes slide-images{
  0% {
    transform:translateX(0px);
  }
  50% {
    transform:translateX(-15vw);
  }
  100% {
      transform:translateX(0px);
    }
}



@media only screen and (max-width: 800px) {
  #header ul {
    display: none;
  }
  .header-left{
    display: none;
  }
  .nfts-container{
  display: none;
  }

  .mobile-button{
    display: block;
    border: 0;
    padding: 10px;
    background: url(./images/main-menu.png) no-repeat;
    width: 20%;
    height: 100%;
    margin-top: 50px;
    margin-left: 20px;
  }

  .mobile-menu{
    padding: 1rem;
    display: block;
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: #00041cea;
    z-index: 999;
    top: 0;
    left: 0;
  }

  .modal-container{
    width: 100%;
    position: absolute;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .details-page{
    display: flex;
    flex-direction:column;
    background: none;
    overflow-y: hidden;
    margin-top: 50px;
    flex-wrap: wrap;
  }

  .details-section{
    display:'flex'; 
    flex-direction: column;
    overflow-y: hidden;
  }
  
}

.panel-button {
  display: inline-block;
  padding: 0.5em;
  margin: 0.5em;
  background-color: #DAE1E5;
  color: #121532;
  border: 1px solid #121532;
  border-radius: 1em;
  cursor: pointer;
}

.panel-button:hover {
  background-color: #FF4800;
  border-top-right-radius: 0;
}

.panel-input {
  display: block;
  padding: 0.5em;
  margin: 0.3em;
  background-color: #FFFFFF;
  color: #111111;
  border: 1px solid #121532;
  border-radius: 1em 0;
}

.button-container{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}